import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress, Icon, ListItem, ListItemIcon, ListItemText,
    MenuItem, Select,
    Tab, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useAlert} from "../../context/AlertContext";
import {WooCommerce} from "../../API";
import {formatter, statusIcon, translateStatus} from "../../components/utils";

export const possibleStatus = ["pending", "processing", "on-hold", "completed", "cancelled", "refunded", "failed"]
const OrdersTable = () => {
    const { alertMessage } = useAlert();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredStatus, setFilteredStatus] = useState('');
    const [filteredId, setFilteredId] = useState('');
    const navigate = useNavigate();

    // Fetch WooCommerce orders
    const fetchOrders = async () => {
        try {
            const response = await WooCommerce.get("orders");
            setOrders(response.data);
            alertMessage("Lista de pedidos actualizada con éxito", "success");
        } catch (error) {
            alertMessage(error.response?.data.message || "Error al obtener los pedidos", "error");
        } finally {
            setLoading(false);
        }
    };
    const handleStatusChange = (event) => {
        setFilteredStatus(event.target.value);
    };
    const filteredOrders = orders.filter((order) => {
        return (
            (!filteredStatus || order.status === filteredStatus) &&
            (!filteredId || order.id.toString().includes(filteredId))
        );
    });

    useEffect(() => {
        fetchOrders();
    }, []);


    if (loading) {
        return (
            <>
                <CircularProgress color="inherit" />
            </>
        );
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignSelf: 'center',
                alignItems: 'center',
                gap:'32px'
            }}>
                <TextField

                    label="ID"
                    value={filteredId}
                    onChange={(e) => setFilteredId(e.target.value)}
                />
                <Select value={filteredStatus} onChange={handleStatusChange} displayEmpty>


                    <MenuItem value="">
                        <ListItem>

                            <ListItemText>
                                Todos los estados
                            </ListItemText>
                        </ListItem>

                    </MenuItem>

                    {possibleStatus.map((item, index)=>{
                        return(
                            <MenuItem value={item} key={index}>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon>{statusIcon(item)}</Icon>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {translateStatus(item)}
                                    </ListItemText>
                                </ListItem>

                            </MenuItem>
                        )
                    })}
                </Select>



            </Box>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Cliente</TableCell>
                            <TableCell>Ciudad</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Estado</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredOrders.map((order) => {

                            return (
                                <TableRow key={order.id} onClick={() => navigate('/pedidos/' + order.id)}>
                                    <TableCell>{order.id}</TableCell>
                                    <TableCell>{new Date(order.date_created).toLocaleDateString()}</TableCell>
                                    <TableCell>{order.shipping.first_name} {order.shipping.last_name}</TableCell>
                                    <TableCell>{order.shipping.city}</TableCell>

                                    <TableCell>{formatter.format(order.total)}</TableCell>
                                    <TableCell>{translateStatus(order.status)}</TableCell>
                                    <TableCell>
                                        <Icon>
                                            {statusIcon(order.status)}
                                        </Icon>
                                    </TableCell>

                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>

            </TableContainer>
        </>
    );
};

export default OrdersTable;
